import React from 'react';

import GoogleMapReact from 'google-map-react';

import Layout from '../components/Layout';

import Scroll from '../components/Scroll';

import compliance from '../assets/images/compliance.jpg';
import idea from '../assets/images/idea.jpg';
import complexity from '../assets/images/complexity.jpg';
import agile from '../assets/images/agile.jpg';
import quality from '../assets/images/quality.jpg';
import support from '../assets/images/support.jpg';
import config from '../../config';

import { graphql } from 'gatsby';

const MarkerComponent = ({ text }) => <div>{text}</div>;

function getContent ( data, name ) {
  return data.index.edges.find( edge => edge.node.frontmatter.id === name).node.html 
}

/*
function getImageURL (data, name) {
  return data.index.edges.find( edge => edge.node.frontmatter.id === name).node.frontmatter.image
} */

const IndexPage = ({ data }) => (

  <Layout fullMenu>
    <section id="banner">
      <div className="inner">
        <h2>{config.heading}</h2>
        <p>{config.subHeading}</p>
        <ul className="actions special">
          <li>
            <Scroll type="id" element="what-we-do">
              <a href="/#what-we-do" className="button primary">
                What we do
              </a>
            </Scroll>
          </li>
        </ul>
      </div>
      <Scroll type="id" element="what-we-do">
        <a href="#what-we-do" className="more">
          Learn More
        </a>
      </Scroll>
    </section>

    <section id="what-we-do" className="wrapper style1 special">
      <div className="inner">
        <header className="major">          
          <div dangerouslySetInnerHTML={{ __html: getContent(data, "what-we-do") }} />
        </header>

      </div>
    </section>

    <section id="principles" className="wrapper alt style2">

      <section className="spotlight">
        <div className="image">
          <img src={compliance} alt="" />
        </div>
        <div className="content">
          <div dangerouslySetInnerHTML={{ __html: getContent(data, "continuous-compliance") }} />
        </div>
      </section>

      <section className="spotlight">
        <div className="image">
          <img src={idea} alt="" />
        </div>
        <div className="content">
        <div dangerouslySetInnerHTML={{ __html: getContent(data, "idea-to-launch") }} />
        </div>
      </section>

      <section className="spotlight">
        <div className="image">
          <img src={complexity} alt="" />
        </div>
        <div className="content">
          <div dangerouslySetInnerHTML={{ __html: getContent(data, "complexity-simplicity") }} />
        </div>
      </section>

      <section className="spotlight">
        <div className="image">
          <img src={agile} alt="" />
        </div>
        <div className="content">
          <div dangerouslySetInnerHTML={{ __html: getContent(data, "agile") }} />
        </div>
      </section>

      <section className="spotlight">
        <div className="image">
          <img src={quality} alt="" />
        </div>
        <div className="content">
          <div dangerouslySetInnerHTML={{ __html: getContent(data, "qms") }} />
        </div>
      </section>
      
      <section className="spotlight">
        <div className="image">
          <img src={support} alt="" />
        </div>
        <div className="content">
          <div dangerouslySetInnerHTML={{ __html: getContent(data, "support-maintenance") }} />
        </div>
      </section>

    </section>

    <section id="expertise" className="wrapper style3 special">
      <div className="inner">
        <header className="major">
        <div dangerouslySetInnerHTML={{ __html: getContent(data, "expertise") }} />
        </header>
        <ul className="features">
          <li className="icon solid fa-cloud">
            <div dangerouslySetInnerHTML={{ __html: getContent(data, "expertise-aws") }} />
          </li>
          <li className="icon solid fa-laptop">
          <div dangerouslySetInnerHTML={{ __html: getContent(data, "expertise-firmware") }} />
          </li>
          <li className="icon solid fa-code">
            <div dangerouslySetInnerHTML={{ __html: getContent(data, "expertise-language") }} />
          </li>
          <li className="icon solid fa-mobile">
            <div dangerouslySetInnerHTML={{ __html: getContent(data, "expertise-mobile") }} />
          </li>
        </ul>
      </div>
    </section>

    <section id="cta" className="wrapper style4">
      <div className="inner">

          < header>
            <div dangerouslySetInnerHTML={{ __html: getContent(data, "where-we-are") }} />
          </header>          

          { /*
          <div style={{ height: '50vh', width: '100%' }}>
            <GoogleMapReact
              bootstrapURLKeys={{ key: "AIzaSyBAyGykwRrIPFQJJxsk6NeZe0ne6kdri7Q" }}
              defaultCenter={{
                lat: 59.95,
                lng: 30.33
              }}
              defaultZoom={11}
            >
              <MarkerComponent
                lat={59.955413}
                lng={30.337844}
                text="My Marker"
              />
            </GoogleMapReact>
          </div>

        */} 

        <ul className="actions stacked">
          <li>
            <a href="mailto:hello@hausegger-inc.com" className="button fit primary">
              Contact us
            </a>
          </li>
        </ul>
      </div>
    </section>
  </Layout>
);

export default IndexPage;

export const query = graphql`
    query {
        index: allMarkdownRemark (
          filter: {fileAbsolutePath: {  glob: "**/index/*.md" } }
        ) {
          edges {
            node {
              frontmatter {id, title, image},
              html
            }
          }
        }        
      }
    `

